<template>
  <div class="Mechanism">
    <div class="head_banner">
      <div class="banner1">
        <h2>为客户提供全球跨境投资及争议解决</h2>
        <h2>一站式法律服务</h2>
        <div class="banner_tel" @click="callPhone('400-662-2729')">
          <img src="@/assets/bannertel.png" alt="" />
          400-662-2729
        </div>
      </div>
    </div>

    <div class="contain" id="htmlContent">
      <div class="title"><i></i>盈科研究<span>YINGKE CONSULTING</span></div>
      <div class="list">
        <ul>
          <li v-for="(x, i) in topDynamics" :key="i">
            <div class="top">
              <div class="model">
                <img src="@/assets/heng.png" alt="" />
                <img
                  :src="require('../../assets/0' + (i + 1) + '.png')"
                  alt=""
                />
                <img src="@/assets/heng.png" alt="" />
              </div>
            </div>
            <div class="bottom">
              <div class="biaoti">{{ x.question }}</div>
              <div class="msg">
                {{ x.answer }}
              </div>
              <div class="time">
                <!-- <span>{{ x.publishYear + " " + x.publishMonthDay }}</span -->
                <span @click="goDetails(x.id)">了解全部>></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="page" v-show="isShowTable">
        <div class="page_cont">
          <div
            class="page_msg"
            v-for="(x, i) in dynamics"
            :key="i"
            @click="goDetails(x.id)"
          >
            <div class="left">
              <h4>{{ x.question }}</h4>
              <div class="text">{{ x.answer }}</div>
            </div>
          </div>
        </div>
        <div class="page_num">
          <el-pagination
            @current-change="topage"
            background
            layout="prev, pager, next"
            :total="pageCount"
          ></el-pagination>
        </div>
      </div>
    </div>
    <!-- <div class="problem">
      <div class="problems">
        <div class="problems_tit">
          <p class="zh">涉外案件（项目）常见问题</p>
          <p class="en">COMMON PROBLEM</p>
          <div class="dian"></div>
        </div>
        <div class="problems_msg">
          <ul>
            <li class="first" v-for="(x, i) in questionList" :key="i">
              <img src="@/assets/h5/lanse.png" alt="" />
              <div class="left" v-if="i % 2 != 0">
                <p>{{ x.question }}</p>
                <div>
                  {{ x.answer }}
                </div>
              </div>
              <div class="right" v-else>
                <p>{{ x.question }}</p>
                <div>
                  {{ x.answer }}
                </div>
              </div>
            </li>
          </ul>
          <div class="problemsbg">
            <div>
              盈科上海<br />
              涉外服务
            </div>
            <p>400-662-2729</p>
            <div class="ask" @click="callPhone('400-662-2729')">立即咨询</div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="contain" id="htmlContent">
      <div class="title"><i></i>盈科机构<span>YINGKE INSTITUTIONS</span></div>
      <div class="Lawfirm">
        <ul>
          <li class="ch" @click="tab = 0" :class="{ active: tab == 0 }">
            <div>盈科中国区律所</div>
          </li>
          <li class="wr" @click="tab = 1" :class="{ active: tab == 1 }">
            <div>盈科全球法律服务网络</div>
          </li>
        </ul>
        <div v-show="tab == 0" class="china">
          <div class="top">
            <h4>机构名单</h4>
            <div class="chinacity">
              <a v-for="(x, i) in domesticOffice" :key="i" :href="x.UrlName" target="_blank">{{
                x.OfficeName
              }}</a>
            </div>
          </div>
          <div class="bottom">
            <h4>机构分布</h4>
            <div class="map">
              <img src="@/assets/chinamap.png" alt="" />
            </div>
          </div>
        </div>
        <div v-show="tab == 1" class="world">
          <div class="top">
            <h4>机构名单</h4>
            <div class="worldcity">
              <a v-for="(x, i) in foreignOffice" :key="i" :href="'https://www.yingkelawyer.com/'+x.UrlName" target="_blank">{{
                x.OfficeName
              }}</a>
            </div>
          </div>
          <div class="bottom">
            <h4>机构分布</h4>
            <div class="map">
              <img src="@/assets/worldmap.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: 0,
      domesticOffice: [],
      foreignOffice: [],

      topDynamics: [], //顶部动态文章
      dynamics: [], //律所动态文章列表
      isShowTable: false,
      DesceOrder: true, //是否降序
      OrderIconVal: 0, //选中的图标值
      CurrentPage: 1, //当前页
      pageCount: -1, //总页数
      title: "",
      img1: require("../../assets/01.png"),
      img2: require("../../assets/02.png"),
      img3: require("../../assets/03.png"),
    };
  },
  created() {
    // this.getOfficeNameList();
    this.$store.state.navChange = 4;
    this.CurrentPage = 1;
    this.getArticles();
  },
  mounted() {
    setTimeout(function () {
      let htmlContent = document.getElementById("htmlContent");
      htmlContent.scrollIntoView({ behavior: "smooth" });
    }, 100);
  },
  methods: {
    // getOfficeNameList() {
    //   this.axios({
    //     type: "get",
    //     url: this.Url.siteUrl,
    //     params: {
    //       _: Math.random(),
    //       t: "get_officename_list",
    //     },
    //   }).then((res) => {
    //     for (var i = 0; i < res.data.length; i++) {
    //       let item = res.data[i];
    //       if (item.OfficeArea == "境内") {
    //         this.domesticOffice.push(item);
    //       } else {
    //         this.foreignOffice.push(item);
    //       }
    //     }
    //   });
    // },
    //跳转文章详情
    goDetails(id) {
      let that = this;
      console.log(id,'666')
      this.$store.state.DesceOrder = that.DesceOrder;
      this.$router.push({
        name: "Mechanism-detail",
        params: {
          id: id,
          order: that.OrderIconVal,
          articleType: "mechanism",
        },
      });
    },
    //获得文章列表
    getArticles() {
      // let _t = Math.random();
      this.axios({
        type: "get",
        url: this.Url.apiUrl + "ForeignQuestion/GetInSideForeignQuestion",
        // params: {
        //   id,
        // },
      }).then((res) => {
        let datas = res.data;
        console.log(res,'datas')
        this.topDynamics = datas.slice(0, 3); //前三篇文章
        datas.splice(0, 3);
        this.dynamics = datas; //其余文章
        this.isShowTable = this.dynamics.length > 0; //判断其余文章是否有内容
        this.pageCount = Math.ceil((res.data.count / 10) * 10);
      });
    },
    //点击分页事件
    topage(val) {
      this.CurrentPage = val;
      this.getArticles();
    },
    //搜索文章
    GetkeyWordArtice() {
      this.CurrentPage = 1;
      this.getArticles();
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/styles/mechanism.scss";
</style>